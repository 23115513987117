import React from 'react';
import Select from 'react-select';
import styled, { ThemeProvider } from 'styled-components';

const options = [
  {
    value: 'it',
    label: 'IT'
  },
  {
    value: 'en',
    label: 'EN'
  }
];

const LanguageChanger = ({ currentLang, setLang, theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Select
          classNamePrefix='language-changer'
          isSearchable={false}
          value={options.find(option => option.value === currentLang)}
          options={options}
          onChange={e => setLang(e.value)}
        />
      </Wrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled.div`
  width: 70px;
  display: flex;

  .css-2b097c-container {
    display: flex;
  }
  .language-changer__value-container {
    padding: 2px 0;
    min-width: 2rem;
  }
  .language-changer__control {
    padding: ${props => props.theme.padding || 0};
    background: ${props => props.theme.background};
    border: 0;
    border-color: ${props => props.theme.background};
    box-shadow: 0 0 0 0 ${props => props.theme.background};
    border-radius: 2px;
    min-height: 0;
  }
  .language-changer__control:hover {
    border-color: ${props => props.theme.background};
    box-shadow: 0 0 0 0 ${props => props.theme.background};
  }
  .language-changer__single-value {
    color: ${props => props.theme.color};
  }
  .language-changer__indicator {
    padding: 0;
    color: ${props => props.theme.color};
  }
  .language-changer__indicator-separator {
    background: none;
  }
  .language-changer__menu {
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background: ${props => props.theme.background};
    margin: 2px 0;
    min-width: 62px;
  }
  .language-changer__option {
    color: ${props => props.theme.color};
  }
  .language-changer__option:hover {
    background: ${props => props.theme.background};
  }
  .language-changer__option--is-focused {
    background: ${props => props.theme.background};
  }
  .language-changer__option--is-selected {
    color: ${props => props.theme.color};
    background: ${props => props.theme.background};
    font-weight: 600;
  }
  .language-changer__option--is-selected:after {
    padding-left: 4px;
    font-family: 'icomoon';
    font-size: 16px;
    font-weight: 900;
    color: #ffd300;
    content: '\\e902';
  }
`;

export default LanguageChanger;
