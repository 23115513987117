import React from 'react';
import produce from 'immer';
import StateProviderService from './StateProviderService';
import FileSaver from 'file-saver';
import Notifier from '../components/Notifier';

const { Provider, Consumer } = React.createContext();
const DEFAULT_TABLE_OPTIONS = {
  pageNumber: 0,
  timeZone: 'UTC'
};
const DEFAULT_PAGINATION = {
  totalPages: 0
};

export default class StateProvider extends React.Component {
  service = new StateProviderService();

  state = {
    contextNames: {
      ummPower: {
        isLoading: false,
        filters: {},
        tableOptions: DEFAULT_TABLE_OPTIONS,
        pagination: DEFAULT_PAGINATION,
        data: []
      },
      ummGas: {
        isLoading: false,
        filters: {},
        tableOptions: DEFAULT_TABLE_OPTIONS,
        pagination: DEFAULT_PAGINATION,
        data: []
      },
      other: {
        isLoading: false,
        filters: {},
        tableOptions: DEFAULT_TABLE_OPTIONS,
        pagination: DEFAULT_PAGINATION,
        data: []
      }
    },
    toponymy: {
      networkError: false
    }
  };

  componentDidMount() {
    const newState = produce(this.state, draft => {
      draft.contextNames.ummPower.isLoading = true;
      draft.contextNames.ummGas.isLoading = true;
      draft.contextNames.other.isLoading = true;
    });

    this.service
      .getToponymy()
      .then(res => {
        this.setState({
          ...this.state.toponymy,
          toponymy: res
        });
      })
      .catch(error => {
        console.error(error);
        Notifier.showError(error.message);
        this.setState(newState =>
          produce(newState, draft => {
            draft.toponymy.networkError = true;
          })
        );
      });

    Object.keys(newState.contextNames).map(contextName =>
      this.updateContext(newState, contextName)
    );
  }

  updateContext = (newState, contextName) => {
    this.service
      .getUMM(newState, contextName)
      .then(res => this.setContextData(newState, contextName, res))
      .catch(error => {
        console.error(error);
        Notifier.showError(error.message);
        this.setState(newState =>
          produce(newState, draft => {
            draft.contextNames[contextName].isLoading = false;
          })
        );
      });
  };

  setContextData = (newState, contextName, res) => {
    let updatedContext = {
      ...newState.contextNames[contextName],
      pagination: {
        ...newState.contextNames[contextName].pagination,
        totalPages: res.totalPages
      },
      tableOptions: {
        ...newState.contextNames[contextName].tableOptions,
        pageSize: res.size
      },
      filters: {
        ...newState.contextNames[contextName].filters
      },
      data: res.content,
      isLoading: false
    };
    this.setState(state =>
      produce(state, draft => {
        draft.contextNames[contextName] = updatedContext;
        draft.contextNames[contextName].isLoading = false;
      })
    );
  };

  onFilterChange = (contextName, key, value) => {
    this.setState(state =>
      produce(state, draft => {
        draft.contextNames[contextName].isLoading = true;
      })
    );
    const newState = produce(this.state, draft => {
      draft.contextNames[contextName].filters[key] = value;
      draft.contextNames[contextName].tableOptions.pageNumber = 0;
    });
    this.updateContext(newState, contextName);
  };

  onTableChange = (tableOptions, contextName) => {
    this.setState(state =>
      produce(state, draft => {
        draft.contextNames[contextName].isLoading = true;
      })
    );
    const newState = produce(this.state, draft => {
      draft.contextNames[contextName].tableOptions = { ...tableOptions };
    });
    this.updateContext(newState, contextName);
  };

  onExport = (fileType, contextName) => {
    this.service
      .getExport(this.state, contextName, fileType)
      .then(res => FileSaver.saveAs(res.data, `${contextName}.${fileType}`))
      .catch(error => {
        console.error(error);
        Notifier.showError(error.message);
      });
  };

  render() {
    const { children } = this.props;
    return (
      <>
        <Notifier />
        <Provider
          value={{
            state: this.state,
            onFilterChange: this.onFilterChange,
            onTableChange: this.onTableChange,
            onExport: this.onExport
          }}
        >
          {children}
        </Provider>
      </>
    );
  }
}

export { Provider, Consumer };
