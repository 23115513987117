const Filters = toponymy => {
  return {
    alwaysVisible: [
      {
        title: 'Publication Date From:',
        key: 'publicationDateFrom',
        type: 'datePicker'
      },
      {
        title: 'Publication Date To:',
        key: 'publicationDateTo',
        type: 'datePicker'
      },
      {
        title: 'Event Date Start:',
        key: 'eventStart',
        type: 'datePicker'
      },
      {
        title: 'Event Date End:',
        key: 'eventStop',
        type: 'datePicker'
      },
      {
        title: 'Time:',
        key: 'timeZone',
        type: 'radio'
      },
      {
        title: 'Export:',
        key: 'export',
        type: 'exportButtons',
        buttons: [
          {
            fileType: 'xml',
            image: 'icon-file-xml',
            url: '#'
          },
          {
            fileType: 'csv',
            image: 'icon-file-csv',
            url: '#'
          }
        ]
      }
    ],
    extra: [
      {
        title: 'Event Status:',
        key: 'eventStatus',
        options: toponymy.EVENTSTATUSENUM || [],
        type: 'select'
      },
      {
        title: 'Market Participant:',
        key: 'marketParticipantCode',
        options: toponymy.MARKETPARTICIPANT_OTHERMARKETINFORMATION || [],
        type: 'select'
      }
    ]
  };
};

export default Filters;
