export default [
  {
    Header: 'Message id',
    accessor: 'messageId',
    fixed: 'left'
  },
  {
    Header: 'Event status',
    accessor: 'eventStatus',
    width: 100
  },
  {
    Header: 'Publication date/time',
    accessor: 'publicationDate',
    width: 190
  },
  {
    Header: 'Event start',
    accessor: 'eventStart',
    width: 190
  },
  {
    Header: 'Event stop',
    accessor: 'eventStop',
    width: 190
  },
  {
    Header: 'Remarks',
    accessor: 'remarks'
  },
  {
    Header: 'Market participant code',
    accessor: 'marketParticipantCode'
  }
];
