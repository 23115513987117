import React from 'react';
import styled from 'styled-components';

import Label from './Label';

const Input = ({ value, onFilterChange, name, label, isDisabled }) => {
  return (
    <Wrapper className='filter'>
      <Label name={name} label={label} />
      <StyledInput
        disabled={isDisabled}
        className='filter filter--input'
        type='text'
        placeholder='Enter a value'
        defaultValue={value}
        onChange={e => onFilterChange(name, e.target.value)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  color: #333;
  background: #fff;
  appearance: none;
  cursor: pointer;
`;

export default Input;
