import React from 'react';
import Select from './filters/Select';
import Input from './filters/Input';
import DatePicker from './filters/DatePicker';
import RadioButton from './filters/RadioButton';
import ExportButtons from './filters/ExportButtons';

const FilterGenerator = props => {
  return printField(props);
};

const printField = props => {
  const {
    field,
    networkError,
    data,
    onFilterChange,
    onTableChange,
    onExport,
    tableOptions
  } = props;

  switch (field.type) {
    case 'input':
      return (
        <Input
          isDisabled={networkError}
          label={field.title}
          name={field.key}
          value={data[field.key]}
          onFilterChange={onFilterChange}
        />
      );
    case 'datePicker':
      return (
        <DatePicker
          isDisabled={networkError}
          label={field.title}
          name={field.key}
          value={data[field.key] ? new Date(data[field.key]) : undefined}
          onFilterChange={onFilterChange}
        />
      );
    case 'select':
      return (
        <Select
          isDisabled={networkError}
          name={field.key}
          label={field.title}
          value={data[field.key]}
          options={field.options}
          onFilterChange={onFilterChange}
          optionValueAccessor={field.accessor}
        />
      );
    case 'radio':
      return (
        <RadioButton
          key={field.key}
          name={field.key}
          label={field.title}
          tableOptions={tableOptions}
          onTableChange={onTableChange}
        />
      );
    case 'exportButtons':
      return (
        <ExportButtons
          label={field.title}
          buttons={field.buttons}
          onExport={onExport}
        />
      );
    default:
      return null;
  }
};

export default FilterGenerator;

// import React from 'react';
// import styled from 'styled-components';

// import Select from './filters/Select';
// import Input from './filters/Input';
// import DatePicker from './filters/DatePicker';
// import RadioButton from './filters/RadioButton';
// import ExportButtons from './filters/ExportButtons';

// const Filters = ({
//   filtersModel,
//   filters,
//   onFilterChange,
//   onTableChange,
//   onExport,
//   tableOptions,
//   timeZone,
//   networkError
// }) => {
//   return (
//     <>
//       <Wrapper>
//         <GroupWrapper>
//           {filtersModel.date.map(filter => {
//             const { title, key } = filter;
//             return (
//               <DatePicker
//                 isDisabled={networkError}
//                 name={key}
//                 key={key}
//                 label={title}
//                 onFilterChange={onFilterChange}
//                 value={filters[key]}
//               />
//             );
//           })}
//         </GroupWrapper>
//         <GroupWrapper>
//           {filtersModel.select.map(filter => {
//             const { title, key, options } = filter;
//             return (
//               <Select
//                 isDisabled={networkError}
//                 name={key}
//                 key={key}
//                 label={title}
//                 options={options}
//                 onFilterChange={onFilterChange}
//                 value={filters[key]}
//               />
//             );
//           })}
//         </GroupWrapper>
//         <GroupWrapper>
//           {filtersModel.text.map(filter => {
//             const { title, key } = filter;
//             return (
//               <Input
//                 isDisabled={networkError}
//                 name={key}
//                 key={key}
//                 label={title}
//                 onFilterChange={onFilterChange}
//                 value={filters[key]}
//               />
//             );
//           })}
//         </GroupWrapper>
//       </Wrapper>
//       <Wrapper>
//         <GroupWrapper>
//           {filtersModel.radio.map(filter => {
//             const { title, key } = filter;
//             return (
//               <RadioButton
//                 name={key}
//                 key={key}
//                 label={title}
//                 value={timeZone}
//                 tableOptions={tableOptions}
//                 onTableChange={onTableChange}
//               />
//             );
//           })}
//         </GroupWrapper>
//         <GroupWrapper>
//           <ExportButtons
//             label={filtersModel.export.title}
//             buttons={filtersModel.export.buttons}
//             onExport={onExport}
//           />
//         </GroupWrapper>
//       </Wrapper>
//     </>
//   );
// };

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;

//   @media (min-width: 768px) {
//     flex-direction: row;
//   }
// `;

// const GroupWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
// `;

// export default Filters;
