import React from 'react';
import styled from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import '../../../../assets/css/datePicker.css';

import Label from './Label';

const DatePicker = ({ value, onFilterChange, label, name, isDisabled }) => {
  const FORMAT = 'YYYY-MM-DD';
  return (
    <Wrapper className='filter'>
      <Label name={name} label={label} />
      <DayPickerInput
        inputProps={{ disabled: isDisabled }}
        format={FORMAT}
        placeholder={FORMAT}
        value={value}
        onDayChange={date => onFilterChange(name, date)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export default DatePicker;
