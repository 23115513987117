import React from 'react';
import styled from 'styled-components';

const Label = ({ name, label }) => {
  return <StyledLabel htmlFor={name}>{label}</StyledLabel>;
};

const StyledLabel = styled.label`
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #333;
`;

export default Label;
