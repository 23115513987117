import styled from 'styled-components';

const Separator = styled.div`
  margin: 0;
  width: 100%;
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
`;

export default Separator;
