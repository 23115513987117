import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

export default class Overlay extends React.Component {
  state = {
    wasTextCopied: false
  };

  fillClipboard = () => {
    var textField = document.createElement('textarea');
    textField.innerText = `${process.env.REACT_APP_API_URL}/rss`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({ wasTextCopied: true });
  };

  dismissTextCopy = () => {
    if (this.state.wasTextCopied) this.setState({ wasTextCopied: false });
  };

  render({ isOpen, toggle } = this.props) {
    return (
      <Background isOpen={isOpen}>
        <Container>
          <CloseWrapper isOpen={isOpen}>
            <CloseButton
              className='icon-close'
              onClick={() => {
                toggle();
                this.dismissTextCopy();
              }}
            />
          </CloseWrapper>
          <ModalWrapper isOpen={isOpen}>
            <Content>
              <TitleWrapper>
                <TitleIcon className='icon-rss' />
                <Title>
                  <Trans i18nKey='rss_popup.title' />
                </Title>
              </TitleWrapper>
              <P>
                <Trans i18nKey='rss_popup.p' />
              </P>
              <Footer>
                <Button
                  wasPressed={this.state.wasTextCopied}
                  onClick={() => this.fillClipboard()}
                >
                  <Trans
                    i18nKey={
                      this.state.wasTextCopied === true
                        ? 'rss_popup.cta_pressed'
                        : 'rss_popup.cta'
                    }
                  />
                </Button>
                <LinkWrapper
                  isHelpHidden={window.location.pathname.includes('/rss')}
                >
                  <LinkIcon className='icon-info' />
                  <A href='/rss' onClick={() => toggle()}>
                    <Trans i18nKey='rss_popup.link' />
                  </A>
                </LinkWrapper>
              </Footer>
            </Content>
          </ModalWrapper>
        </Container>
      </Background>
    );
  }
}

const Background = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.isOpen ? 'rgba(0, 0, 0, 0.7);' : 'rgba(0, 0, 0, 0)'};
  transition: background 250ms;
  z-index: 999;
`;

const Container = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  cursor: pointer;
  padding: 0 0 16px 0;
  background: none;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: #ffd300;
`;

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 17rem;

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    width: 30rem;
  }

  @media (min-width: 768px) {
    width: 40rem;
  }
`;

const CloseWrapper = styled(Wrapper)`
  flex-direction: row-reverse;
`;

const ModalWrapper = styled(Wrapper)`
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: ${props => (props.isOpen ? '20rem' : '19rem')};
  transition: height 500ms;
  background: #fff;

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    height: ${props => (props.isOpen ? '17rem' : '16rem')};
  }

  @media (min-width: 768px) {
    height: ${props => (props.isOpen ? '20rem' : '19rem')};
  }
`;

const Content = styled.div`
  padding: 0 2rem;

  @media (min-width: 768px) {
    padding: 0 4rem;
  }
`;

const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const Icon = styled.i`
  margin-right: 10px;
`;

const TitleIcon = styled(Icon)`
  font-size: 0.9rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1.3rem;

  @media (min-width: 768px) {
    font-size: 1.7rem;
  }
`;

const P = styled.p`
  margin: 20px 0 60px 0;

  @media (min-width: 768px) {
    margin: 20px 0 35px 0;
  }
`;

const Footer = styled.footer`
  flex-direction: column;
`;

const Button = styled.button`
  cursor: pointer;
  padding: 15px 0;
  background: ${props => (props.wasPressed === true ? '#c7c7c7' : '#ffd300')};
  min-width: 13rem;
  font-size: 0.8rem;
  border: none;
  outline: none;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 500ms;

  @media (min-width: 768px) {
    min-width: 30rem;
    font-size: 1rem;
  }
`;

const LinkWrapper = styled.div`
  display: ${props => (props.isHelpHidden ? 'none' : 'block')};
  color: #666666;
  align-items: baseline;
  margin-top: 20px;
`;

const LinkIcon = styled(Icon)`
  font-size: 0.8rem;
`;

const A = styled.a`
  color: #666666;

  &:visited {
    color: #666666;
  }
`;
