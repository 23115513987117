import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    width: 100%;
    margin: 0;
    font-family: Source Sans Pro;
    font-weight: normal;
    font-stretch: normal;
  }

  input {
    &:disabled {
      opacity: 1;
    }
  }

  .filter--input, .filter--select {
    border: none;
    padding: 3px 15px;
    border-radius: 4px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.17);
    font-family: Source Sans Pro;
    font-weight: 300;
    font-stretch: normal;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  .ReactTable {
    border: 0!important;
  }

  .ReactTable .rt-table {
    overflow-y: hidden!important;
  }

  .ReactTable .-striped {
    background: #f5f5f5;
  }

  .ReactTable .-header {
    height: 4rem!important;
  }

  .ReactTable .rt-tbody .rt-td {
    border-right: 0!important;
  }
  
  .ReactTable .rt-tbody .rt-tr-group {
    border: 0!important;
  }

  .ReactTable .rt-th {
    display: flex;
    justify-content: center;
    border-top: 0!important;
    border-right: 0!important;
    border-bottom: 2px solid #ffd300!important;
    border-left: 0!important;
  }
  
  .ReactTable .rt-th {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: normal!important;
    font-size: .8rem;
    font-weight: 900;
    text-transform: uppercase;
  }

  .ReactTable .rt-tr {
    height: 50px;
  }

  .ReactTable .rt-td, .ReactTable .rt-th  {
    &:first-child {
      word-break: break-all;
    }

    &:first-child {
      border-right: 2px solid #ffd300!important;
    }
  }

  .ReactTable .rt-td {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    white-space: normal!important;
    padding: 0 15px;
    font-size: .8rem;
  }

  .ReactTable .-pagination, .ReactTable .rt-thead.-header {
    box-shadow: none!important;
    border-top: none!important;
    padding-top: 1rem!important;
  }

  .ReactTable .-btn {
    font-family: 'icomoon' !important;
    background: none!important;

    &:not([disabled]):hover{
      color: #ffd300!important;
    }

    &:before {
      content: "\\e906"
    }
  }

  .ReactTable .-previous {
    transform: rotate(180deg)
  }

  .ReactTable .-pageJump input {
    font-family: Source Sans Pro!important;
    width: 50px!important;
    border: none!important;
    text-align: right!important;
  }

  .ReactTable .select-wrap select {
    font-family: Source Sans Pro!important;
    border: none!important;
  }

  .ReactTable .-totalPages {
    padding-left: 6px!important;
    font-size: 16px;

    @media (min-width: 768px) {
      padding-left: 8px!important;
    }
  }
`;

export default GlobalStyles;
