import axios from 'axios';

const TOPONYMY_URL = '/api/toponymy/list';
const UMM_POWER_URL = '/api/umm/power';
const UMM_GAS_URL = '/api/umm/gas';
const OTHER_URL = '/api/otherMarketInformation';

const UMM_URLS = {
  ummPower: UMM_POWER_URL,
  ummGas: UMM_GAS_URL,
  other: OTHER_URL
};

export default class StateProviderService {
  constructor() {
    this.axios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  }

  async getToponymy() {
    const response = await this.axios.get(TOPONYMY_URL);
    return response.data.data;
  }

  async getUMM(state, contextName) {
    const response = await this.axios.post(`${UMM_URLS[contextName]}/list`, {
      ...state.contextNames[contextName].tableOptions,
      filters: state.contextNames[contextName].filters
    });
    return response.data.data;
  }

  async getExport(state, contextName, exportType) {
    const response = await this.axios.post(
      `${UMM_URLS[contextName]}/export-${exportType}`,
      {
        ...state.contextNames[contextName].tableOptions,
        filters: state.contextNames[contextName].filters
      },
      { responseType: 'blob' }
    );
    return response;
  }
}
