import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import StateProvider from '../../context/StateProvider';
import LayoutMain from '../layout/Main';
import Sizer from '../layout/Sizer';
import Regulations from './misc/Regulations';
import Button from './misc/Button';
import Tab from './Tab';
import UmmGasFiltersModel from './models/ummGas/Filters';
import UmmPowerFiltersModel from './models/ummPower/Filters';
import OtherFiltersModel from './models/other/Filters';

const tabs = {
  ummPower: {
    name: 'UMM Power',
    path: '/umm-power',
    model: UmmPowerFiltersModel
  },
  ummGas: {
    name: 'UMM Gas',
    path: '/umm-gas',
    model: UmmGasFiltersModel
  },
  other: {
    name: 'Other Market Information',
    path: '/other',
    model: OtherFiltersModel
  }
};

const Main = ({ location, hasPadding }) => {
  return (
    <Sizer>
      <StateProvider>
        <LayoutMain hasPadding={hasPadding}>
          <Regulations />
          <H1>
            <Trans i18nKey="main.title" />
          </H1>
          <Navigation>
            <Wrapper>
              {Object.keys(tabs).map(contextName => (
                <Button
                  selected={location.pathname === tabs[contextName].path}
                  key={contextName}
                  text={tabs[contextName].name}
                  path={tabs[contextName].path}
                />
              ))}
            </Wrapper>
            <div>
              <Line />
            </div>
          </Navigation>
          <Switch>
            {Object.keys(tabs).map(tabKey => {
              return (
                <Route
                  key={tabKey}
                  exact
                  path={tabs[tabKey].path}
                  render={() => (
                    <Tab
                      contextName={tabKey}
                      filtersModel={tabs[tabKey].model}
                    />
                  )}
                />
              );
            })}
            <Route exact path="/" render={() => <Redirect to="/umm-power" />} />
            <Route path="/:anything" render={() => <Redirect to="/404" />} />
          </Switch>
        </LayoutMain>
      </StateProvider>
    </Sizer>
  );
};

const H1 = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`;

const Navigation = styled.nav`
  margin: 15px 0 1rem 0;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Line = styled.div`
  margin-top: -3px;
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #e6e6e6;
  border-right: 0;
`;

export default Main;
