import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Sizer from './layout/Sizer';
import Main from './layout/Main';

const NotFound = () => {
  return (
    <Sizer>
      <Main>
        <H1>
          <Trans i18nKey='404.title' />
          <br />
          <Span>404</Span>
        </H1>
        <StyledLink to='/'>
          <Trans i18nKey='404.link.text' />
        </StyledLink>
      </Main>
    </Sizer>
  );
};

const H1 = styled.h1`
  margin: 0;
  font-size: 2.5rem;
`;

const Span = styled.span`
  font-size: 4rem;
  font-weight: 700;
  color: #666666;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding-top: 1.5rem;
  font-size: 1.3rem;
  text-decoration: none;
  color: #cc1745;
`;

export default NotFound;
