import React from 'react';
import styled from 'styled-components';

import Label from './Label';

const Select = ({
  value,
  onFilterChange,
  options,
  label,
  name,
  isDisabled
}) => {
  return (
    <Wrapper className='filter'>
      <Label name={name} label={label} />
      <StyledSelect
        disabled={isDisabled}
        className='filter--select'
        name={name}
        value={value}
        onChange={e => onFilterChange(name, e.target.value)}
        required
      >
        <option value=''>-</option>
        {options.map(option => (
          <option value={option.code} key={option.code}>
            {option.description}
          </option>
        ))}
      </StyledSelect>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 46px;
  padding-right: 3rem;
  color: #333;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiA4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNiA4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMzMzMzMzO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTUuNSwwLjhMOCw4TDAuNSwwLjhMMS4zLDBMOCw2LjVMMTQuNywwTDE1LjUsMC44eiIvPg0KPC9zdmc+DQo=');
  background-color: #fff;
  background-position: calc(100% - 15px);
  background-size: 18px;
  background-repeat: no-repeat;
  appearance: none;
  cursor: pointer;

  &:invalid {
    color: #757575;
  }

  &::-ms-expand {
    display: none;
  }
`;

export default Select;
