import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Trans } from 'react-i18next';
import Sizer from '../layout/Sizer';
import LanguageChanger from './LanguageChanger';
import Separator from '../layout/Separator';
import MenuButton from './menu/MenuButton';
import MenuContent from './menu/MenuContent';

import logo from '../../assets/img/logo.svg';

const languageChangerTheme = {
  background: '#fff',
  color: '#666'
};

class Header extends React.Component {
  state = {
    isMenuOpen: false
  };

  toggleMenu = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }));
  };

  render({ currentLang, setLang, toggleRssPopup, isFixed } = this.props) {
    return (
      <>
        <GlobalStyles isMenuOpen={this.state.isMenuOpen} />
        <StyledHeader>
          <DesktopSection>
            <Sizer background='#1d1d1d'>
              <LinkContainer>
                <A href='https://www.eni.com/it_IT/home.page'>
                  <Trans i18nKey='header.visit' /> <Span>eni.com</Span>
                </A>
                <VerticalSeparator />
              </LinkContainer>
            </Sizer>
            <Sizer>
              <ActionContainer>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button
                    className='icon-rss'
                    onClick={() => toggleRssPopup()}
                  />
                  <VerticalSeparator margin='0 20px' />
                  <LanguageChanger
                    currentLang={currentLang}
                    setLang={setLang}
                    theme={languageChangerTheme}
                  />
                </div>
              </ActionContainer>
            </Sizer>
            <HeaderSeparator />
          </DesktopSection>
          <StickyContainer>
            <Sticky id='logobar' isFixed={isFixed}>
              <Sizer>
                <LogoBar>
                  <a href='/'>
                    <Logo src={logo} />
                  </a>
                  <MenuContainer>
                    <MenuButton toggle={this.toggleMenu.bind(this)} />
                  </MenuContainer>
                </LogoBar>
              </Sizer>
              <HeaderSeparator />
            </Sticky>
          </StickyContainer>
          <MenuContainer>
            <MenuContent
              toggleMenu={() => this.toggleMenu.bind(this)}
              isMenuOpen={this.state.isMenuOpen}
              currentLang={currentLang}
              setLang={setLang}
              togglePopup={() => toggleRssPopup()}
            />
          </MenuContainer>
        </StyledHeader>
      </>
    );
  }
}

const StyledHeader = styled.header`
  width: 100%;
  background: #fff;
  top: 0;
`;

const GlobalStyles = createGlobalStyle`
  body {
    overflow: ${props => (props.isMenuOpen ? 'hidden' : 'visible')};
    position: ${props => (props.isMenuOpen ? 'fixed' : 'static')};
  }
`;

const DesktopSection = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const StickyContainer = styled.div`
  @media (min-width: 768px) {
    margin-top: ${props => (props.isFixed ? '91px' : '0')};
  }
`;

const Sticky = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  @media (min-width: 768px) {
    position: ${props => (props.isFixed ? 'fixed' : 'static')};
  }
`;

const HeaderSeparator = styled(Separator)`
  border-color: #d1d1d1;
`;

const VerticalSeparator = styled.div`
  margin: ${props => props.margin};
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 1px;
  border-color: #d1d1d1;
`;

const LinkContainer = styled.div`
  padding: 0 60px;
  height: 40px;
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
`;

const Button = styled.button`
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  color: #1d1d1d;
  outline: none;
  border: none;
  background: none;
`;

const ActionContainer = styled.div`
  padding: 10px 60px;
  height: 30px;
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
`;

const MenuContainer = styled.div`
  display: flex;

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoBar = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 15px;

  @media (min-width: 768px) {
    padding: 12px 60px;
  }
`;

const A = styled.a`
  min-width: 80px;
  text-decoration: none;
  color: #fff;
  padding: 12px;
`;

const Span = styled.span`
  color: #fed300;
`;

const Logo = styled.img`
  width: auto;
  height: 3.5rem;
`;

export default Header;
