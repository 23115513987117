import React from 'react';
import styled from 'styled-components';

const ExportButtons = ({ label, buttons, onExport }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <ButtonsWrapper>
        {buttons.map(button => {
          const { fileType, image, url } = button;
          return (
            <A href={url} key={fileType}>
              <Button
                className={`filter--input ${image}`}
                onClick={() => onExport(fileType)}
              />
            </A>
          );
        })}
      </ButtonsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const A = styled.a`
  text-decoration: none;
  padding-right: 10px;
`;

const Button = styled.button`
  height: 46px;
  width: 3.5rem;
  background: #fff;
  color: #0885ae;
  font-size: 1.9rem;
  text-align: center;
  padding: 0 10px;

  &:hover {
    background-color: #fed300;
  }
`;

const Label = styled.span`
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #333;
`;

export default ExportButtons;
