import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import Main from '../components/layout/Main';

import Sizer from './layout/Sizer';
import Breadcrumbs from './layout/Breadcrumbs';

const Rss = ({ hasPadding, toggleRssPopup }) => {
  return (
    <Sizer>
      <Main hasPadding={hasPadding}>
        <Breadcrumbs />
        <H1>
          <Trans i18nKey='rss.title' />
        </H1>
        <P>
          <Trans i18nKey='rss.p0' />
        </P>
        <P>
          <Trans i18nKey='rss.p1' />
          <Cta onClick={() => toggleRssPopup()}>
            <Trans i18nKey='rss.cta' />
          </Cta>
          <Trans i18nKey='rss.p2' />
        </P>
      </Main>
    </Sizer>
  );
};

const H1 = styled.h1`
  font-weight: normal;
  margin: 0;
  line-height: 1.19;
  letter-spacing: 0.5px;
  color: #1d1d1d;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const P = styled.p`
  margin: 0;
  padding: 15px 0;
  font-size: 18px;
  color: #666666;

  @media (min-width: 768px) {
    margin: 0;
    padding: 25px 0;
    font-size: 16px;
  }
`;

const Cta = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

export default Rss;
