import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GlobalStyles from './GlobalStyles';
import './assets/css/fonts.css';
import I18nTranslator, {
  changeLanguage
} from './components/translation/I18nTranslator';
import SmallDeviceHider from './components/SmallDeviceHider';
import Header from './components/header/Header';
import Overlay from './components/Overlay';
import Main from './components/main/Main';
import Rss from './components/Rss';
import NotFound from './components/NotFound';
import Footer from './components/Footer';

export default class App extends React.Component {
  state = {
    lang: 'en',
    rssPopupIsOpen: false,
    isHeaderFixed: false
  };

  setLang = value => {
    this.setState({ lang: value });
    changeLanguage(value);
  };

  componentDidMount() {
    const headerHeight = document.getElementById('logobar').offsetTop;
    window.addEventListener('scroll', () => this.handleScroll(headerHeight));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleRssPopup() {
    this.setState({ rssPopupIsOpen: !this.state.rssPopupIsOpen });
  }

  handleScroll(headerHeight) {
    const isHeaderFixed = window.pageYOffset > headerHeight;
    this.setState({ isHeaderFixed });
  }

  render() {
    return (
      <I18nTranslator>
        <GlobalStyles />
        <SmallDeviceHider />
        <Wrapper isOpen={this.state.rssPopupIsOpen}>
          <Overlay
            isOpen={this.state.rssPopupIsOpen}
            toggle={this.toggleRssPopup.bind(this)}
          />
          <Header
            currentLang={this.state.lang}
            setLang={this.setLang}
            toggleRssPopup={this.toggleRssPopup.bind(this)}
            isFixed={this.state.isHeaderFixed}
          />
          <Router>
            <Switch>
              <Route
                exact
                path='/404'
                render={props => (
                  <NotFound {...props} hasPadding={this.state.isHeaderFixed} />
                )}
              />
              <Route
                exact
                path='/rss'
                render={props => (
                  <Rss
                    {...props}
                    hasPadding={this.state.isHeaderFixed}
                    toggleRssPopup={this.toggleRssPopup.bind(this)}
                  />
                )}
              />
              <Route
                path='/'
                render={props => (
                  <Main {...props} hasPadding={this.state.isHeaderFixed} />
                )}
              />
            </Switch>
          </Router>
          <Footer />
        </Wrapper>
      </I18nTranslator>
    );
  }
}

const Wrapper = styled.div`
  display: none;

  @media (min-width: 320px) {
    display: block;
    width: 100%;
    height: 100%;
    overflow: ${props => (props.isOpen ? 'hidden' : 'visible')};
    position: ${props => (props.isOpen ? 'fixed' : 'static')};
  }
`;
