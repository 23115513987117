import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Button = ({ selected, path, text }) => {
  return (
    <StyledLink to={path}>
      <StyledButton selected={selected}>
        <Span selected={selected}>{text}</Span>
      </StyledButton>
    </StyledLink>
  );
};

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  border-top: 0;
  border-left: 0;
  border-bottom: ${props =>
    props.selected ? '4px solid #fed300' : '4px solid rgba(0,0,0,0)'};
  border-right: 0;
  outline: none;
  background: none;
  color: #666;
`;

const StyledLink = styled(Link)`
  height: 3.5rem;
  width: 15rem;
  text-decoration: none;
`;

const Span = styled.span`
  font-size: 16px;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  font-family: Source Sans Pro;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.01px;
`;

export default Button;
