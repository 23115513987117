import React from 'react';
import styled from 'styled-components';
import Sizer from './layout/Sizer';
import Separator from './layout/Separator';
import { Trans } from 'react-i18next';

import logo from '../assets/img/logo-bianco.svg';

const sections = [
  {
    title_key: 'footer.section_0.title',
    links: [
      {
        text: 'RASS.FinancialRegulation@eni.com',
        url: 'mailto:RASS.FinancialRegulation@eni.com'
      }
    ]
  },
  {
    title_key: 'footer.section_1.title',
    links: [
      {
        text: 'ACER Guidance on REMIT',
        url: 'https://documents.acer-remit.eu/guidance-on-remit-2/'
      },
      {
        text: 'Piattaforma Informazioni Privilegiate PiP',
        url: 'https://pip.ipex.it'
      },
      {
        text: 'TTPC',
        url: 'https://www.ttpc.eni.com/it/comunicazioni-mercato.html'
      },
      {
        text: 'Greenstream',
        url: 'https://www.greenstreambv.com/it/comunicazioni-al-mercato.html'
      },
      {
        text: 'Total E&P UK',
        url: 'https://www.total.uk/total-ep-uk-upstream-operational-information'
      },
      {
        text: 'Gassco Flow',
        url: ' http://flow.gassco.no'
      },
      {
        text: 'Snam',
        url:
          'http://www.snam.it/en/transportation/UE-fulfillments-reporting/UMM'
      }
    ]
  },
  {
    title_key: 'footer.section_2.title',
    links: [
      {
        text_key: 'footer.section_2.links.link_0.text',
        url: '/rss'
      }
    ]
  }
];

const Footer = () => {
  return (
    <StyledFooter>
      <Sizer background='#1a1a1a'>
        <a href='/'>
          <Logo src={logo} />
        </a>
      </Sizer>
      <Sizer background='#222'>
        <Wrapper>
          <Sections>
            <Brand>©Remit</Brand>
            <Brand>
              <Trans i18nKey='footer.brand' />
            </Brand>
          </Sections>
          <Sections>
            {sections.map(section => (
              <LinkSection key={section.title_key}>
                <Title>
                  <Trans i18nKey={section.title_key} />
                </Title>
                <FooterSeparator />
                {section.links.map(link => (
                  <A key={link.url} target='_blank' href={link.url}>
                    {link.text === undefined ? (
                      <Trans i18nKey={link.text_key} />
                    ) : (
                      link.text
                    )}
                  </A>
                ))}
              </LinkSection>
            ))}
          </Sections>
        </Wrapper>
      </Sizer>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  font-size: 14px;
  margin-top: 15px;

  @media (min-width: 768px) {
    margin-top: 25px;
  }
`;

const FooterSeparator = styled(Separator)`
  color: #979797;
  margin: 12px 0 20px 0;

  @media (min-width: 1024px) {
    max-width: 10rem;
  }
`;

const Logo = styled.img`
  padding: 20px 1.5rem;
  height: 2rem;

  @media (min-width: 768px) {
    padding: 12px 4.5rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #aaa;
  padding: 1.5rem;

  @media (min-width: 768px) {
    padding: 30px 70px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 4.5rem 40px 4.5rem;
  }
`;

const Brand = styled.div`
  min-width: 3.2rem;
  font-weight: bold;
  margin: 10px 0;
  line-height: 1.29;

  &:first-child {
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    margin: 0 15px 0 4.25rem;

    &:first-child {
      margin: 0;
    }
  }
`;

const Sections = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
`;

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;

  @media (min-width: 1024px) {
    padding: 0 2rem;
    min-width: 12rem;

    &:last-child {
      padding-right: 0;
    }
  }
`;

const Title = styled.div`
  color: #fed300;
  text-transform: uppercase;
`;

const A = styled.a`
  margin: 6px 0;
  text-decoration: none;
  color: #ddd;
  line-height: 1.43;
`;

export default Footer;
