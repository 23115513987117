export default [
  {
    Header: 'Message id',
    accessor: 'messageId',
    fixed: 'left',
    width: 130
  },
  {
    Header: 'Affected asset or unit',
    accessor: 'affectedAssetDesc',
    width: 140
  },
  {
    Header: 'Event status',
    accessor: 'eventStatus'
  },
  {
    Header: 'Type of event',
    accessor: 'eventType',
    width: 140
  },
  {
    Header: 'Event start',
    accessor: 'eventStart',
    width: 190
  },
  {
    Header: 'Event stop',
    accessor: 'eventStop',
    width: 190
  },
  {
    Header: 'Unit of measurement',
    accessor: 'unitMeasure'
  },
  {
    Header: 'Direction',
    accessor: 'direction'
  },
  {
    Header: 'Unavailable capacity',
    accessor: 'unavailableCapacity'
  },
  {
    Header: 'Available capacity',
    accessor: 'availableCapacity'
  },
  {
    Header: 'Technical capacity',
    accessor: 'technicalCapacity'
  },
  {
    Header: 'Balancing zone',
    accessor: 'balancingZone',
    width: 130
  },
  {
    Header: 'Affected asset or unit EIC code',
    accessor: 'affectedAssetCode',
    width: 120
  },
  {
    Header: 'Reason of the unavailability',
    accessor: 'unavailabilityReason'
  },
  {
    Header: 'Remarks',
    accessor: 'remarks',
    width: 150
  },
  {
    Header: 'Type of unavailability',
    accessor: 'unavailabilityType'
  },
  {
    Header: 'Publication date/time',
    accessor: 'publicationDate',
    width: 190
  },
  {
    Header: 'Market participant',
    accessor: 'marketParticipantDesc'
  },
  {
    Header: 'Market participant code',
    accessor: 'marketParticipantCode',
    width: 120
  }
];
