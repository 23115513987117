import React from 'react';
import i18next from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import en from './lang/en';
import it from './lang/it';

i18next.use(initReactI18next).init({
  resources: { en, it },
  fallbackLng: 'en',
  debug: process.env.REACT_APP_TRANSLATE_DEBUG
});

const changeLanguage = language => {
  i18next.changeLanguage(language);
};

export { i18next, changeLanguage };

export default ({ children }) => (
  <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
);
