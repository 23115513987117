import React from 'react';
import styled from 'styled-components';

const Menu = ({ toggle }) => {
  return (
    <Button onClick={toggle}>
      <Icon className='icon-hamburger' />
    </Button>
  );
};

const Button = styled.button`
  cursor: pointer;
  color: #1d1d1d;
  outline: none;
  border: none;
  background: none;
`;

const Icon = styled.i`
  font-size: 1.5rem;
`;

export default Menu;
