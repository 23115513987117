import React from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default class Notifier extends React.Component {
  static showError = message =>
    toast.error(<Msg icon='icon-toast-error' message={message} />);

  render = () => (
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHove
    />
  );
}

const Msg = ({ icon, message }) => (
  <>
    <Span className={icon} />
    {message}
  </>
);

const Span = styled.span`
  display: inline-block;
  margin-right: 8px;
`;
