import React from 'react';
import styled from 'styled-components';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles';
import spinner from '../../../assets/img/spinner.gif';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default class Table extends React.Component {
  onSortChange = result => {
    const { id, desc } = result[0];
    this.props.onTableChange({
      ...this.props.tableOptions,
      sortField: id,
      sortDirection: desc ? 'DESC' : 'ASC'
    });
  };

  onPageSizeChange = (pageSize, pageNumber) => {
    this.props.onTableChange({
      ...this.props.tableOptions,
      pageSize: pageSize,
      pageNumber: pageNumber
    });
  };

  onPageChange = pageNumber => {
    this.props.onTableChange({
      ...this.props.tableOptions,
      pageNumber: pageNumber
    });
  };

  render() {
    const { isLoading, data, model, pagination, tableOptions } = this.props;

    return (
      <Wrapper>
        {isLoading ? (
          <Loading>
            <Spinner src={spinner} alt='Loading...' />
          </Loading>
        ) : (
          <></>
        )}
        <ReactTableFixedColumns
          manual
          className='ReactTable-striped'
          data={data}
          columns={model}
          getTrProps={(state, rowInfo) => {
            return {
              style: {
                textDecoration:
                  rowInfo.row.eventStatus === 'Dismissed'
                    ? 'line-through'
                    : 'none'
              }
            };
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          resizable={false}
          filterable={false}
          sortable={true}
          page={tableOptions.pageNumber}
          pages={pagination.totalPages}
          minRows={0}
          pageSize={tableOptions.pageSize}
          onPageChange={this.onPageChange.bind(this)}
          onPageSizeChange={this.onPageSizeChange.bind(this)}
          onSortedChange={this.onSortChange.bind(this)}
          previousText=''
          nextText=''
          loadingText=''
          noDataText=''
          pageText='Page'
          ofText='of'
          rowsText='rows'
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
`;

const Spinner = styled.img`
  position: absolute;
  width: 40px;
  height: auto;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
`;

const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 3;
`;
