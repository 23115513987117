const Filters = toponymy => {
  return {
    alwaysVisible: [
      {
        title: 'Publication Date From:',
        key: 'publicationDateFrom',
        type: 'datePicker'
      },
      {
        title: 'Publication Date To:',
        key: 'publicationDateTo',
        type: 'datePicker'
      },
      {
        title: 'Event Date Start:',
        key: 'eventStart',
        type: 'datePicker'
      },
      {
        title: 'Event Date End:',
        key: 'eventStop',
        type: 'datePicker'
      },
      {
        title: 'Affected Asset or Unit Code:',
        key: 'affectedAssetCode',
        options:
          toponymy.EIC_GAS !== undefined
            ? toponymy.EIC_GAS.map(({ code }) => ({ code, description: code }))
            : [],
        type: 'select'
      },
      {
        title: 'Direction:',
        key: 'direction',
        options: toponymy.DIRECTIONENUM || [],
        type: 'select'
      },
      {
        title: 'Time:',
        key: 'timeZone',
        type: 'radio'
      },
      {
        title: 'Export:',
        key: 'export',
        type: 'exportButtons',
        buttons: [
          {
            fileType: 'xml',
            image: 'icon-file-xml',
            url: '#'
          },
          {
            fileType: 'csv',
            image: 'icon-file-csv',
            url: '#'
          }
        ]
      }
    ],
    extra: [
      {
        title: 'Event Status:',
        key: 'eventStatus',
        options: toponymy.EVENTSTATUSENUM || [],
        type: 'select'
      },
      {
        title: 'Type of Event:',
        key: 'eventType',
        options: toponymy.EVENT_TYPE_GAS || [],
        type: 'select'
      },
      {
        title: 'Balancing Zone:',
        key: 'balancingZone',
        options:
          toponymy.BALANCING_ZONE !== undefined
            ? toponymy.BALANCING_ZONE.map(({ code }) => ({ code, description: code }))
            : [],
        type: 'select'
      },
      {
        title: 'Type of Unavailability:',
        key: 'unavailabilityType',
        options: toponymy.UNAVAILABILITY_TYPE || [],
        type: 'select'
      },
      {
        title: 'Market Participant:',
        key: 'marketParticipantCode',
        options: toponymy.MARKETPARTICIPANT_UMMGAS || [],
        type: 'select'
      }
    ]
  };
};

export default Filters;
