import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

export const SmallDeviceHider = () => {
  return (
    <Hider>
      <Wrapper>
        <Trans i18nKey='smallDeviceHider.text' />
      </Wrapper>
    </Hider>
  );
};

const Hider = styled.div`
  height: 100vh;
  background: #fed300;

  @media (min-width: 320px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  position: relative;
  transform: rotate(-90deg);
  top: 45%;
`;

export default SmallDeviceHider;
