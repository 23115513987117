import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Breadcrumbs = () => {
  let pathName = `home${window.location.pathname}`;
  pathName.slice(-1) === '/' && (pathName = pathName.slice(0, -1));
  const pages = pathName.split('/');

  return (
    <Wrapper>
      {pages.map((page, counter) => {
        let str = '';
        for (let i = counter; i >= 0; i--) str = `${pages[i]}/${str}`;
        return (
          <Container key={counter}>
            <StyledLink to={str.replace('home', '').slice(0, -1)}>
              {page.replace(/-/g, ' ')}
            </StyledLink>
            {counter !== pages.length - 1 && <Span>/</Span>}
          </Container>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 24px;
`;

const Container = styled.div``;

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: #333;
  font-weight: normal;

  &:last-child {
    color: #c3c3c3;
  }
`;

const Span = styled.span`
  display: inline-block;
  padding: 0 8px;
`;

export default Breadcrumbs;
