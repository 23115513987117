import React from 'react';
import styled from 'styled-components';

import Label from './Label';

const RadioButton = ({ name, label, tableOptions, onTableChange }) => {
  return (
    <Wrapper className='filter'>
      <Label name={name} label={label} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <label>
          <input type='radio' hidden />
          <Button
            selected={tableOptions && tableOptions.timeZone === 'UTC'}
            style={{ marginRight: '10px' }}
            className='filter--input'
            onClick={() => onTableChange({ ...tableOptions, [name]: 'UTC' })}
          >
            UTC
          </Button>
        </label>
        <label>
          <input type='radio' hidden />
          <Button
            selected={tableOptions && tableOptions.timeZone === 'IT'}
            className='filter--input'
            onClick={() => onTableChange({ ...tableOptions, [name]: 'IT' })}
          >
            ITA
          </Button>
        </label>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Button = styled.button`
  height: 46px;
  color: #333;
  background: ${props => (props.selected ? '#fed300' : '#fff')};
  text-align: center;
  width: 3.5rem;
  padding: 0 10px;
`;

export default RadioButton;
