import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const MenuItem = ({ links }) => {
  return links.map(link => (
    <Wrapper key={link.url} onClick={link.onClick}>
      <Icon className={link.icon || ''} />
      <A href={link.url}>
        <Trans i18nKey={link.text_key} />
      </A>
      <GoIcon className='icon-arrow-right--long' />
    </Wrapper>
  ));
};

const Wrapper = styled.div`
  cursor: pointer;
  padding: 12px 0;

  &:last-child {
    padding-bottom: 0;
  }
`;

const Icon = styled.i`
  margin-right: ${props => (props.className === '' ? '0' : '8px')};
  color: #fff;
`;

const GoIcon = styled.i`
  padding-left: 12px;
  color: #ffd300;
`;

const A = styled.a`
  color: #fff;
  text-decoration: none;
`;

export default MenuItem;
