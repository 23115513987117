import React from 'react';
import styled from 'styled-components';
import MenuSection from './MenuSection';
import MenuItem from './MenuItem';
import Separator from '../../layout/Separator';
import LanguageChanger from '../LanguageChanger';
import Mdi from '@mdi/react';
import { mdiMenuUp } from '@mdi/js';

const contents = (toggleMenu, togglePopup) => [
  {
    title: 'menu.contents_0.title',
    links: [
      {
        text_key: 'menu.contents_0.link_0.text',
        url: 'https://www.eni.com/it_IT/home.page'
      },
      {
        icon: 'icon-rss',
        text_key: 'menu.contents_0.link_1.text',
        url: '#',
        onClick: () => {
          togglePopup();
          toggleMenu();
        }
      }
    ]
  }
];

const languageChangerTheme = {
  background: '#333',
  color: '#fff',
  padding: '8px'
};

const MenuContent = ({
  isMenuOpen,
  toggleMenu,
  currentLang,
  setLang,
  togglePopup
}) => {
  return (
    <Content isMenuOpen={isMenuOpen}>
      <IconWrapper isMenuOpen={isMenuOpen}>
        <MenuArrow path={mdiMenuUp} size={2.5} color='#1d1d1d' />
      </IconWrapper>
      <Container isMenuOpen={isMenuOpen}>
        {contents(toggleMenu, togglePopup).map(content => (
          <div key={content.title}>
            <Wrapper>
              <MenuSection title={content.title}>
                <MenuItem links={content.links} />
              </MenuSection>
            </Wrapper>
            <MenuSeparator />
          </div>
        ))}
        <Wrapper>
          <MenuLanguageChanger
            currentLang={currentLang}
            setLang={setLang}
            theme={languageChangerTheme}
          />
        </Wrapper>
      </Container>
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
  padding-top: ${props => (props.isMenuOpen ? 'calc(3.5rem + 28px)' : '0')};
  height: ${props => (props.isMenuOpen ? 'calc(100vh - 56px)' : '0')};
  background: #1d1d1d;
  transition: height 500ms;
`;

const IconWrapper = styled.div`
  display: ${props => (props.isMenuOpen ? 'block' : 'none')};
`;

const Container = styled.div`
  display: ${props => (props.isMenuOpen ? 'block' : 'none')};
`;

const Wrapper = styled.div`
  padding: 28px 16px;
`;

const MenuArrow = styled(Mdi)`
  position: absolute;
  right: 0.2rem;
  top: 48px;
  z-index: 101;
`;

const MenuSeparator = styled(Separator)`
  border-color: #999;
`;

const MenuLanguageChanger = styled(LanguageChanger)`
  color: #fff;
`;

export default MenuContent;
