import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import Table from './misc/Table';
import FilterGenerator from './misc/FilterGenerator';
import { Consumer } from '../../context/StateProvider';
import UmmPowerTableModel from './models/ummPower/Table';
import UmmGasTableModel from './models/ummGas/Table';
import OtherTableModel from './models/other/Table';

const tableModels = {
  ummPower: UmmPowerTableModel,
  ummGas: UmmGasTableModel,
  other: OtherTableModel
};

export default class Tab extends React.Component {
  state = {
    areFiltersExpanded: false
  };

  render({ contextName, filtersModel } = this.props) {
    return (
      <Consumer>
        {({ state, onFilterChange, onTableChange, onExport }) => {
          const filledFiltersModel = filtersModel(state.toponymy);
          return (
            <>
              <FiltersWrapper>
                <AlwaysVisible>
                  {filledFiltersModel.alwaysVisible.map(filter => (
                    <FilterGenerator
                      key={filter.key}
                      field={filter}
                      onFilterChange={(key, value) =>
                        onFilterChange(contextName, key, value)
                      }
                      tableOptions={
                        state.contextNames[contextName].tableOptions
                      }
                      onTableChange={tableOptions =>
                        onTableChange(tableOptions, contextName)
                      }
                      onExport={fileType => onExport(fileType, contextName)}
                      data={state.contextNames[contextName].filters}
                      timeZone={
                        state.contextNames[contextName].tableOptions.timeZone
                      }
                      networkError={state.toponymy.networkError}
                    />
                  ))}
                </AlwaysVisible>
                <ButtonWrapper>
                  <MoreButton
                    onClick={() =>
                      this.setState({
                        areFiltersExpanded: !this.state.areFiltersExpanded
                      })
                    }
                  >
                    <Icon
                      className='icon-arrow-down'
                      isVisible={this.state.areFiltersExpanded}
                    />
                    <Desc>
                      <Trans
                        i18nKey={
                          this.state.areFiltersExpanded
                            ? 'tab.less_filters'
                            : 'tab.more_filters'
                        }
                      />
                    </Desc>
                  </MoreButton>
                </ButtonWrapper>
                <Extra isVisible={this.state.areFiltersExpanded}>
                  {filledFiltersModel.extra.map(filter => (
                    <FilterGenerator
                      key={filter.key}
                      field={filter}
                      onFilterChange={(key, value) =>
                        onFilterChange(contextName, key, value)
                      }
                      tableOptions={
                        state.contextNames[contextName].tableOptions
                      }
                      onTableChange={tableOptions =>
                        onTableChange(tableOptions, contextName)
                      }
                      onExport={fileType => onExport(fileType, contextName)}
                      data={state.contextNames[contextName].filters}
                      timeZone={
                        state.contextNames[contextName].tableOptions.timeZone
                      }
                      networkError={state.toponymy.networkError}
                    />
                  ))}
                </Extra>
              </FiltersWrapper>
              <Wrapper>
                {state.contextNames[contextName].data.length !== 0 ? (
                  <Table
                    isLoading={state.contextNames[contextName].isLoading}
                    data={state.contextNames[contextName].data}
                    model={tableModels[contextName]}
                    pagination={state.contextNames[contextName].pagination}
                    tableOptions={state.contextNames[contextName].tableOptions}
                    onTableChange={tableOptions =>
                      onTableChange(tableOptions, contextName)
                    }
                  />
                ) : (
                  <NoData>
                    <Trans i18nKey='tab.table_error_message' />
                  </NoData>
                )}
              </Wrapper>
            </>
          );
        }}
      </Consumer>
    );
  }
}

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const AlwaysVisible = styled(Filters)`
  z-index: 4;
`;

const Extra = styled(Filters)`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MoreButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin: 0.6rem;
  min-width: 130px;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
`;

const Icon = styled.span`
  transform: ${props => (props.isVisible ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 300ms;
  font-size: 0.5rem;
`;

const Desc = styled.span`
  font-weight: bold;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  margin: 1rem 10px;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.3);
  border: 1px dotted rgba(0, 0, 0, 0.3);

  @media (min-width: 768px) {
    margin: 2rem;
  }
`;
