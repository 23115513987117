import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import Sizer from '../../layout/Sizer';
import Separator from '../../layout/Separator';

const Regulations = () => {
  return (
    <Sizer
      children={
        <RegulationsContainer>
          <H1>
            <Trans i18nKey="regulations.title" />
          </H1>
          <P>
            <Trans i18nKey="regulations.p0" />
          </P>
          <RegulationSeparator />
          <P>
            <Trans i18nKey="regulations.p1" />
          </P>
        </RegulationsContainer>
      }
    />
  );
};

const RegulationsContainer = styled.div``;

const H1 = styled.h1`
  margin: 0;
  font-weight: normal;
  line-height: 1.19;
  letter-spacing: 0.5px;
  color: #1d1d1d;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const P = styled.p`
  margin: 0;
  font-weight: normal;
  padding: 15px 0;
  font-size: 18px;

  @media (min-width: 768px) {
    margin: 0;
    padding: 25px 0;
    font-size: 16px;
  }
`;

const RegulationSeparator = styled(Separator)`
  border-color: #d1d1d1;
`;

export default Regulations;
