import styled from 'styled-components';
import React from 'react';

const Sizer = props => (
  <FullSize {...props}>
    <Container>{props.children}</Container>
  </FullSize>
);
export default Sizer;

const FullSize = styled.div`
  width: 100%;
  background: ${props => props.background};
`;

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;
