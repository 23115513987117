import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const MenuSection = ({ title, children }) => {
  return (
    <Wrapper>
      <Title>
        <Trans i18nKey={title} />
      </Title>
      <div>{children}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Title = styled.span`
  display: inline-block;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999;
`;

export default MenuSection;
