import React from 'react';
import styled from 'styled-components';

const Main = ({ children, hasPadding }) => {
  return <StyledMain hasPadding={hasPadding}>{children}</StyledMain>;
};

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  padding: calc(3.5rem + 50px) 15px 20px 15px;
  min-height: calc(100vh - 100px);

  @media (min-width: 768px) {
    padding: ${props => (props.hasPadding ? 'calc(172px - 3.5rem)' : '30px')}
      60px 30px 60px;
  }
`;

export default Main;
